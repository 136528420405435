<template>
  <div class="home">
    <Nav paths="/" />
    <div class="banner">
      <div class="banner-carousel">
        <el-carousel indicator-position="outside" height="750px" arrow="hover" :interval="3000">
          <el-carousel-item v-for="(item,index) in carousel" :key="index">
            <el-image :src="item"></el-image>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="news-dynamic">
      <div class="main">
        <div class="filler"></div>
        <div class="left">
          <div class="video-title">
            <img class="icon" :src="require('@/assets/home/icon1.png')">
            <span class="title">泰克尼康技术</span>
          </div>
          <div class="video">
            <video width="570" height="420" preload="auto" :controls="One_video" ref="main_v">
              <source :src="realmURL + videoOne[0].video_url" type="video/mp4" v-if="videoOne[0]" />
            </video>
            <div class="video-player" @click="players(0)" v-if="!One_video"><i class="el-icon-caret-right"></i></div>
          </div>
        </div>
        <div class="right">
          <div class="top">
            <h3>新闻动态</h3>
            <h4>news dynamics</h4>
          </div>
          <div class="bottom">
            <el-table :data="tableData" :show-header="false" style="width: 100%" @row-click="toContents">
              <el-table-column prop="title" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="times" show-overflow-tooltip width="185">
              </el-table-column>
            </el-table>
            <router-link class="show-more" to="/news">查看更多 ></router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="aboat" data-aos="fade-up" data-aos-duration="1000">
      <div class="left">
        <h1><span>About</span> Technican</h1>
        <h2>关于泰克尼康</h2>
        <div class="text">
          <p>泰克尼康科技有限公司(简称泰克尼康，英文缩写TCNC)</p>
          <p>成立于2012年，是冻眠速冻技术应用整体解决方案服务商，致力于冻眠速冻技术的研究开发和推广应用。</p>
          <p>冻眠是一种颠覆传统的液体高速冻结技术，可以让食品药品长久新鲜。由于冻眠速冻技术对人类持续发展作出重大贡献，因而获得联合国的大力倡导。</p>
          <p>公司早于2017年便进入了冻眠速冻技术领域的耕耘，开创了中国冻眠速冻时代。我们致力于横向打造冻眠速冻应用场景，传播冻眠文化，塑造冻眠品牌，让食(药)品更新鲜、更环保、更健康;社会更节约，生活更美好！</p>
        </div>
      </div>
      <div class="right">
        <img :src="require('@/assets/about/bg1.png')" alt="">
      </div>
    </div>
    <div class="technology-unique">
      <div class="unique-title">冻眠技术特点</div>
      <div class="unique-item"></div>
    </div>
    <div class="relevant-video">
      <div class="en-title" data-aos="fade-up" data-aos-duration="1000">Related Video</div>
      <div class="chs-title" data-aos="fade-up" data-aos-duration="1000">相关视频</div>
      <div class="relevant-video-list" data-aos="fade-up" data-aos-duration="1000">
        <el-row :gutter="20">
          <el-col :span="8" v-for="(item, index) in video_list" :key="index" style="height: 255px;margin-bottom:20px;">
            <div class="relevant-video-list-item">
              <video width="450" height="255" preload="auto" :controls="!item.play" :ref="`video_${item.id}`" style="border-radius: 8px;">
                <source :src="realmURL + item.video_url" type="video/mp4" />
              </video>
              <div class="video-player" @click="players(item.id)" v-if="item.play"><i class="el-icon-caret-right"></i></div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="news-consult" v-if="null">
      <div class="news-first-three">
        <div class="news-title">
          <div class="title">新闻资讯</div>
          <router-link class="routerlink" to="/news">查看更多<i class="el-icon-d-arrow-right"></i></router-link>
        </div>
        <div class="news-items">
          <el-col :span="7" v-for="(item, index) in new_list" :key="index">
            <el-card :body-style="{ padding: '0px',border:'0' }" shadow="hover">
              <img :src="realmURL + item.top_pic" class="image">
              <div class="news-content">
                <div class="news-content-title">{{item.title}}</div>
                <p class="news-content-value">{{item.summry}}</p>
                <div class="news-clearfix">
                  <time class="time">{{item.add_time | dateFilter}}</time>
                  <el-tag type="info">{{item.type == 1 ? '公司新闻' : '行业新闻'}}</el-tag>
                </div>
                <div class="news-selects">
                  <el-button type="primary" plain @click="selector(item.id)">查看详情</el-button>
                </div>
              </div>
            </el-card>
          </el-col>
        </div>
      </div>
    </div>
    <el-backtop :visibility-height="300" :bottom="100" />
  </div>
</template>

<script>
import Nav from '@/components/nav'

export default {
  data () {
    return {
      carousel: [
        require('@/assets/carousel/carousel1.jpg'),
        require('@/assets/carousel/carousel2.jpg'),
        require('@/assets/carousel/carousel3.jpg'),
        require('@/assets/carousel/carousel4.jpg'),
      ],
      tableData: [],
      videoOne: [],
      video_list: [],
      One_video: false,
      new_list: [],
    }
  },
  created () {
    window.scrollTo(0, 0);
  },
  mounted () {
    this.getVideoList()
    this.getDynamicList()
    this.getNewsList()
  },
  methods: {
    // 相关视频列表
    getVideoList () {
      this.$axios.post('http://freeze.ganmiaoedu.com/website/site/getvideolist')
        .then(res => {
          if (res.data.code == 1) {
            this.videoOne = res.data.data.filter(item => {
              return item.sort == 1
            }).slice(0, 1)
            this.video_list = res.data.data.map(item => {
              return {
                ...item,
                play: true
              }
            }).filter(item => {
              return item.sort != 1
            }).slice(0, 6)
          }
        })
    },
    // 公司动态
    getDynamicList () {
      this.$axios.post('http://freeze.ganmiaoedu.com/website/site/getdynamic')
        .then(res => {
          if (res.data.code == 1) {
            let resdata = res.data.data.map(item => {
              return {
                ...item,
                times: this.$moment(Number(item.add_time + '000')).format('YYYY-MM-DD')
              }
            })
            this.tableData = resdata
          }
        })
    },
    // 新闻咨询
    getNewsList () {
      this.$axios.post('http://freeze.ganmiaoedu.com/website/site/getnews')
        .then(res => {
          if (res.data.code == 1) {
            this.new_list = res.data.data
          }
        })
    },
    // 视频同步播放限制为一个
    players (id) {
      if (id) {
        this.video_list = this.video_list.map(item => {
          this.$refs[`video_${item.id}`][0].pause()
          return {
            ...item,
            play: item.id != id
          }
        })
        this.$refs.main_v.pause()
        this.One_video = false
        this.$refs[`video_${id}`][0].play()
      } else {
        this.video_list = this.video_list.map(item => {
          this.$refs[`video_${item.id}`][0].pause()
          return {
            ...item,
            play: true
          }
        })
        this.$refs.main_v.play()
        this.One_video = !this.One_video
      }
    },
    // atype: 1 公司动态，2 新闻
    // 公司动态
    selector (id) {
      this.$router.push({ path: '/detail', query: { id: id, atype: 2 } })
    },
    // 新闻详情
    toContents (data) {
      this.$router.push({ path: '/detail', query: { id: data.id, atype: 1 } })
    },
  },

  components: {
    Nav,
  }
}
</script>

<style lang="less" scoped>
.home {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 1600px;
  .banner {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 750px;
    margin: 0 auto;
    .banner-carousel {
      width: 1920px;
      height: 750px;
      /deep/.el-image__inner {
        width: 1920px;
        height: 750px;
        -webkit-user-drag: none;
      }
      /deep/.el-carousel__button {
        height: 4px;
      }
      /deep/.el-carousel__indicators--outside button {
        background-color: #00a0e9;
      }
    }
    // .banner-bg {
    //   width: 1920px;
    //   height: 750px;
    //   background: url("../assets/home.png") no-repeat;
    //   background-size: cover;
    // }
  }
  .news-dynamic {
    position: relative;
    display: flex;
    width: 100%;
    min-width: 1600px;
    height: 712px;
    margin: 100px auto;
    background: white;
    background-attachment: fixed;
    .main {
      position: relative;
      display: flex;
      justify-content: space-between;
      width: 1400px;
      height: 712px;
      margin: 0 auto;
      .filler {
        position: absolute;
        top: 0;
        left: -40%;
        width: 1400px;
        height: 712px;
        background-color: #f7f7f7;
      }
      .left {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 570px;
        margin: 100px 0;
        .video-title {
          display: flex;
          align-items: center;
          width: 310px;
          height: 48px;
          padding-bottom: 30px;
          margin-bottom: 30px;
          border-bottom: 4px solid #00a0e9;
          .icon {
            width: 48px;
            height: 48px;
          }
          .title {
            margin-left: 15px;
            font-size: 36px;
            font-weight: bold;
            color: #0a0c0d;
            letter-spacing: 5px;
          }
        }
        .video {
          width: 570px;
          height: 420px;
          position: relative;
          border-radius: 8px;
          video {
            background-color: #000;
            border-radius: 8px;
          }
        }
      }
      .right {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 637px;
        height: 712px;
        border-radius: 10px;
        box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.04),
          10px -10px 10px rgba(0, 0, 0, 0.04),
          -10px 10px 10px rgba(0, 0, 0, 0.04),
          -10px -10px 10px rgba(0, 0, 0, 0.04);
        .top {
          width: 637px;
          height: 154px;
          padding: 35px 30px;
          border-radius: 10px 10px 0 0;
          background-color: #f3ce13;
          h3 {
            font-size: 32px;
            font-weight: bold;
            line-height: 40px;
            color: #fff;
            letter-spacing: 3px;
          }
          h4 {
            font-size: 32px;
            font-weight: bold;
            text-transform: uppercase;
            line-height: 40px;
            color: rgba(255, 255, 255, 0.3);
          }
        }
        .bottom {
          position: relative;
          display: flex;
          width: 637px;
          height: 558px;
          border-radius: 0 0 10px 10px;
          background-color: #00a0e9;
          .show-more {
            position: absolute;
            left: 50%;
            bottom: 15px;
            transform: translateX(-50%);
            width: 100%;
            font-size: 18px;
            color: #fff;
            text-decoration: none;
            text-align: center;
            &:hover {
              color: #ddd;
            }
          }
          /deep/ .el-table__empty-text {
            font-size: 30px;
            color: #fff;
          }
          /deep/ .el-table tr,
          /deep/ .el-table,
          /deep/ .el-table__expanded-cell,
          .el-table--border::after,
          .el-table--group::after,
          .el-table::before {
            background-color: transparent;
          }
          /deep/ .el-table tbody tr:hover > td {
            background-color: rgba(255, 255, 255, 0.2) !important;
          }
          /deep/ .el-table__cell {
            padding: 26px 26px;
            .cell {
              font-size: 22px;
              color: #fff;
            }
          }
          /deep/ .el-table td.el-table__cell,
          /deep/ .el-table th.el-table__cell.is-leaf {
            border: 0;
          }
          /deep/ .el-table__row {
            cursor: pointer;
          }
        }
      }
    }
  }
  .aboat {
    position: relative;
    display: flex;
    width: 1400px;
    margin: 60px auto;
    .left {
      flex: 1;
      display: flex;
      flex-direction: column;
      width: 663px;
      h1 {
        font-size: 48px;
        font-weight: bold;
        line-height: 64px;
        color: #00a0e9;
        span {
          font-weight: 400;
          color: #66c6f2;
        }
      }
      h2 {
        font-size: 36px;
        font-weight: bold;
        line-height: 47px;
        color: #0a0c0d;
      }
      .text {
        font-size: 22px;
        line-height: 38px;
        color: #0a0c0d;
        margin-top: 50px;
        text-align: left;
      }
    }
    .right {
      flex: 1;
      display: flex;
      align-items: flex-end;
      margin-left: 40px;
      overflow: hidden;
      img {
        width: 680px;
        height: 460px;
        transition: all 1.5s;
      }
      img:hover {
        transform: scale(1.25);
      }
    }
  }
  .technology-unique {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-width: 1600px;
    height: 697px;
    margin: 100px auto 0;
    background: url("../assets/home/bg2.png") no-repeat;
    background-size: 100% 100%;
    background-attachment: fixed;
    .unique-title {
      font-size: 36px;
      font-weight: bold;
      line-height: 50px;
      color: #fff;
      margin: 100px 0 80px;
    }
    .unique-item {
      width: 1381px;
      height: 370px;
      background: url("../assets/home/bg3.png") no-repeat;
    }
  }
  .relevant-video {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 1400px;
    margin: 100px auto 0;
    background: white;
    background-attachment: fixed;
    .en-title {
      font-size: 48px;
      font-weight: bold;
      color: #e6e6e6;
      letter-spacing: 10px;
      text-align: center;
    }
    .chs-title {
      font-size: 36px;
      font-weight: bold;
      color: #000;
      letter-spacing: 5px;
      text-align: center;
      margin-top: -30px;
    }
    .relevant-video-list {
      position: relative;
      width: 1400px;
      margin-top: 60px;
      margin-bottom: 20px;
      &-item {
        position: relative;
        width: 450px;
        height: 255px;
        background: #000;
        border-radius: 8px;
      }
    }
  }
  .news-consult {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 1600px;
    margin: 50px auto 0;
    background-color: #f7f7f7;
    background-attachment: fixed;
    .news-first-three {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 1400px;
      margin: 50px auto;
      .news-title {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 1400px;
        margin: 0 auto 60px;
        .title {
          font-size: 36px;
          font-weight: bold;
          line-height: 50px;
          color: #000;
          letter-spacing: 3px;
          text-indent: 20px;
          border-left: 3px solid #00a0e9;
        }
        .routerlink {
          position: relative;
          box-sizing: border-box;
          font-size: 22px;
          color: #b5b6b6;
          text-decoration: none;
          padding: 5px 0;
        }
        .routerlink:hover {
          color: #6c6d6e;
        }
      }
      .news-items {
        position: relative;
        display: flex;
        justify-content: space-around;
        width: 1400px;
        /deep/ .image {
          width: 100%;
          height: 264px;
          object-fit: cover;
        }
        .el-card__body {
          width: 440px !important;
        }
        .news-content {
          padding: 0 20px;
          .news-content-title {
            font-size: 26px;
            font-weight: bold;
            color: #000;
            letter-spacing: 3px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin: 20px 0;
          }
          .news-content-value {
            font-size: 20px;
            color: #6c6d6e;
            line-height: 30px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            word-break: break-all;
            text-indent: 40px;
            margin: 0 0 20px;
          }
          .news-clearfix {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .time {
              font-size: 22px;
              color: #b5b6b6;
            }
            /deep/ .el-tag.el-tag--info {
              font-size: 16px;
              padding: 0 12px;
              color: #b5b6b6;
              background-color: #e6e6e6;
              border-radius: 15px;
            }
          }
          .news-selects {
            display: flex;
            justify-content: flex-end;
            margin: 20px 0;
            /deep/ .el-button--primary.is-plain {
              color: #6c6d6e;
              font-size: 20px;
              border-radius: 10px;
              border-color: #a9a9a9;
              background: #fff;
            }
            /deep/ .el-button--primary.is-plain:focus,
            /deep/ .el-button--primary.is-plain:hover {
              background: #409eff;
              border-color: #409eff;
              color: #fff;
            }
          }
        }
      }
    }
  }
  // 视频播放按钮
  .video-player {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 999;
    color: #fff;
    .el-icon-caret-right {
      font-size: 50px;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 8px;
      padding: 0 10px;
    }
  }
}
</style>